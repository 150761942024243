import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
// import { faChair } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableRestaurantIcon from '@mui/icons-material/TableRestaurantOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { htmlDecode } from './Utils';

export function AreaComponent(props) {
  const { t } = useTranslation();

  const activeArea = Number(props.data.settingsData.activeArea) ? Number(props.data.settingsData.activeArea) : `${t('LANG_AREA_LABEL')}`;
  const [areaDesc, setAreaDesc] = useState({ d: '', area: `${t('LANG_AREA_LABEL')}` });
  const [areaLabel, setAreaLabel] = useState('');
  const { areaList } = props.data.settingsData;
  const { iconColor } = props.data.settingsData;

  const areaBtn = [];
  let d;
  function areaSelect(e) {
    e.preventDefault();
    const selectedArea = `${e.currentTarget.value}`;
    window.$('.ui-tooltip').remove();
    for (const areainnerval of areaList) {
      if (Number(areainnerval.id) === Number(selectedArea)) {
        d = htmlDecode(areainnerval.desc);
        setAreaLabel(areainnerval.name);
        setAreaDesc({ ...areaDesc, d, area: areainnerval.name });
        break;
      }
    }
    window.$("[accbtn='formblock']").click();

    props.areaChange(e);
  }
  let areaLabelDis = `${t('LANG_AREA_LABEL')}`;
  if (props.data.settingsData.activeArea && areaLabel) {
    areaLabelDis = areaLabel;
  }

  Object.entries(areaList).forEach(
    ([key, value]) => {
      const desc = ((value.desc).replace(/(<([^>]+)>)/gi, '')).trim();
      let toolDesc = '';
      if (desc != '') {
        toolDesc = <i title={value.desc} className="HighlightedDesc"><InfoIcon sx={{ color: iconColor }} /></i>;
        window.$(document, '.HighlightedDesc').tooltip({
          content() {
            return window.$(this).prop('title');
          },
        });
      }
      areaBtn.push(<button key={value.id} onClick={areaSelect} className={activeArea === value.id ? 'slotActive' : ''} value={value.id}>
        {value.name}
        {toolDesc}
        {' '}

      </button>);
    },
  );

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton accbtn="areablock">
          <TableRestaurantIcon sx={{ color: iconColor }} />
          <span className="guests-opl">{areaLabelDis}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className={`${props.activeTag == 'tab3' ? 'active' : ''} content areas contentsecond`}>
          {areaBtn}
          {/* <p dangerouslySetInnerHTML={{__html: areaDesc.d}}></p> */}
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}
