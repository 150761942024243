export default function validate(values) {
  const errors = {};
  if (!values.no_of_guests) {
    errors.no_of_guests = 'Enter number of guest going for enquiry';
  }
  if (!values.enquiry_date || values.enquiry_date === 'Invalid date') {
    errors.enquiry_date = 'Enter number of guest going for enquiry';
  }

  if (!values.start_time && values.start_time !== '-') {
    errors.start_time = 'Select start time';
  }
  if (!values.duration) {
    errors.duration = 'Select duration';
  }
  if (!values.first_name) {
    errors.first_name = 'Enter firt name';
  }
  if (!values.last_name) {
    errors.last_name = 'Enter last name';
  }
  if (!values.email) {
    errors.email = 'Enter email';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.phone_number) {
    errors.phone_number = 'Enter phone number';
  } else if (values.phone_number.length > 12) {
    errors.phone_number = 'Phone number can not enter morthan 12';
  }
  if (values.agreecheckbox === undefined || (values.agreecheckbox !== undefined && !values.agreecheckbox)) {
    errors.agreecheckbox = 'Must select the terms and condition';
  }
  return errors;
}
