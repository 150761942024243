import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import axios from 'axios';
import moment from 'moment';
import { DatePicker } from './DatePicker';


import {
  AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import TimeIcon from '@mui/icons-material/ScheduleOutlined';

import SuggestionRestaurantComponent from './SuggestionRestaurantComponent';
import ContactUsComponent from './ContactUsComponent';
import LoaderComponent from './loaderComponent';

const initialSlotsDomHandle = {
  showSlots: false,
  showSugg_1: false,
  showSugg_2: false,
  isSuggestionClick: false,
  isAvailableDates: false
};

function TimeSlotComponents(props) {
  const { timeSlots, iconColor, restaurantId, selectedDate, selectedGuest } = props.data.settingsData;
  const notesData = props.data.settingsData.dailyNotes;
  const { t } = useTranslation();
  const [time, selectTime] = useState({ time: `${t('LANG_TIME_SLOTS')}` });
  const [showContactusflag, setShowContactUs] = useState(false);
  const [timeSlotsDomHandle, setTimeSlotsDomHandle] = useState(initialSlotsDomHandle);
  const [loader, setLoader] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const d = props.data.settingsData.adanceBooking.days.advance_booking_day.split('/');
  const maxdate = new Date(d);

  function selectedSlot(e, poolRenderData = '') {
    const slotTime = `${e.currentTarget.value}`;
    selectTime({ ...time, time: slotTime });
    if (props.data.settingsData.res_settings.area_path === 'yes' || (poolRenderData !== '' && poolRenderData.settings.area_path === 'yes')) {
      window.$("[accbtn='areablock']").click();
    } else {
      window.$("[accbtn='formblock']").click();
    }
    props.slotChange(e);
  }

  useEffect(() => {
    setSuggestions([]);
  }, []);

  useEffect(() => {
    setLoader(true);
    if (props.data.settingsData.showTimeSlot) {
      setTimeSlotsDomHandle({ ...initialSlotsDomHandle, showSlots: true });
    }

    if (props.data.settingsData.showSuggestionMsg_1) {
      setTimeSlotsDomHandle({ ...initialSlotsDomHandle, showSugg_1: true });
      const fetchData = async () => {
        const res = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/widget/getSuggestRestaurantList`,
          {
            restaurant_id: restaurantId,
            date: selectedDate,
            no_of_guests: selectedGuest,
          },
        );
        setSuggestions(res.data.data);
      };
      fetchData();
    }

    if (props.data.settingsData.showSuggestionMsg_2) {
      setTimeSlotsDomHandle({ ...initialSlotsDomHandle, showSugg_2: true });
    }

    if (!props.data.settingsData.isContactUsClicked) {
      setShowContactUs(false);
    }
    setLoader(false);
  }, [props]);

  function showContactus() {
    setShowContactUs(true);
    props.isContactClicked();
  }

  function fetchAvailableDates(date) {
    setLoader(true)
    setTimeSlotsDomHandle({ ...initialSlotsDomHandle, availableDates: true });
    axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/widget/getAvailableDates`,
      {
        restaurant_id: restaurantId,
        date: date || selectedDate,
        no_of_guests: selectedGuest,
      },
    ).then((response) => {
      setTimeSlotsDomHandle({ ...initialSlotsDomHandle, isAvailableDates: true });
      setAvailableDates(response.data.data)
    }).finally(() => {
      setLoader(false)
    })
  }

  const activeSlot = props.data.settingsData.poolActiveSlot
    ? props.data.settingsData.poolActiveSlot : props.data.settingsData.activeSlot;

  function renderAccordionItemPanel() {
    const { showSlots, showSugg_1, showSugg_2, isSuggestionClick, isAvailableDates } = timeSlotsDomHandle;
    const { settingsData, poolHandler } = props.data;
    const restaurantName = settingsData.poolRestaurantName || settingsData.restaurantInfo.name;

    if (props.data.settingsData.showLoaderInSlot || loader) {
      return <LoaderComponent />;
    }

    if (showContactusflag) {
      return <ContactUsComponent data={props.data} contactusSubmit={props.contactusHandler} />;
    }

    if (isAvailableDates) {
        return (
          <div id="calendar">
            <DatePicker
                onDateChange={dateSelect}
                initialDate={currentDate}
                dateFormat="dd.mm.yy"
                minDate={new Date()}
                onChangeMonthYear={monthChange}
                onBeforeShowDay={beforeShowDay}
                lang={i18n.language}
                className="DatePicker2"
                maxDate={maxdate || ''}
                id="datepickertest2"
              />
            </div>
        )
    }

    if (isSuggestionClick) {
      return (
        <>
          <div className="poolSelectLabel">{t('CHOOSE_A_RESTAURANT')}</div>
          { suggestions.map((suggestion) => (
            <SuggestionRestaurantComponent
              poolhandler={poolHandler}
              key={suggestion.id}
              renderData={suggestion}
              selectedSlot={selectedSlot}
            />
          ))}
        </>
        
      )
    }

    if (showSlots) {
      const selectedDate = moment(settingsData.selectedDate, 'DD.MM.YYYY').format('MM/DD/YYYY').toString();
      let noteArr = notesData[selectedDate];
      if (!!noteArr && !(noteArr instanceof Array)) {
        noteArr = noteArr.note;
      }

      return (
        <div className="content slots">
          {timeSlots.map((slot) => <button type="button" key={slot} onClick={selectedSlot} className={activeSlot === slot ? 'slotActive' : ''} value={slot}>{slot}</button>)}
          <br />
          {noteArr?.map((noteTxt, index) => (
            <div key={`note-${index}`} className="singleDayNote">
              <div className="heading">
                Info
                {noteArr.length > 1 ? index + 1 : ''}
                :
              </div>
              <div className="notetooltip" dangerouslySetInnerHTML={{ __html: noteTxt }} />
            </div>
          ))}
        </div>
      );
    }

    if (showSugg_1 || showSugg_2) {
      return (
        <div className="content suggession_text">
          <div>
            {showSugg_1 ? t('LANG_SUGGESTION_2_TEXT_1') : t('LANG_SUGGESTION_1_TEXT_1')}
            {selectedDate}
            {" "}
            { t('LANG_LOCATION') }
            {" "}
            {restaurantName}
            .
          </div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: showSugg_1 ? t('LANG_SUGGESTION_2_TEXT_2') : t('LANG_SUGGESTION_1_TEXT_2') }} />
          {showSugg_1
            && (
            <>
              <button onClick={() => setTimeSlotsDomHandle({ ...initialSlotsDomHandle, isSuggestionClick: true })}>{t('LANG_SHOW_SUGGESTION')}</button>
              <div>{t('LANG_OR')}</div>
            </>
            )}
          <button type="button" onClick={showContactus}>{t('LANG_CONTACT_US')}</button>
          { showSugg_2 && 
            <>
              <div>{t('LANG_OR')}</div>
              <button onClick={() => {fetchAvailableDates()}} type="button">{t('LANG_AVAILABLE_DATES')}</button>
            </>
          }
          
        </div>
      );
    }

    return null;
  }

  function dateSelect(date) {
    window.$("[accbtn='slotblock']").click();
    props.data.dateHandler(date)
  }

  function monthChange(year, month) {
    const date = moment('01.' + month + '.' + year, 'DD.MM.YYYY').format('DD.MM.YYYY').toString()
    setCurrentDate(date)
    fetchAvailableDates(date)
  }
  
  function beforeShowDay(date) {
    if (i18n.language === 'fi') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
    } else if (i18n.language === 'en') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
    }
    const day = moment(date).format('DD.MM.YYYY').toString()
    if (availableDates.includes(day)) {
      return [true, '', ''];
    }
    return [false, '', ''];
  }

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton accbtn="slotblock">
          <TimeIcon sx={{ color: iconColor }} />
          <span className="guests-opl">{!activeSlot ? t('LANG_TIME_SLOTS') : time.time}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {renderAccordionItemPanel()}
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export { TimeSlotComponents };
