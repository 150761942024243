import React from 'react';

export class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    const { tooltipdata } = props;
    this.state = {
      tooltipdata,
    };
    this.datepickerContainer = React.createRef();
  }

  componentDidMount() {
    window.$(this.datepickerContainer.current).datepicker({
      onSelect: this.props.onDateChange,
      defaultDate: this.props.initialDate,
      dateFormat: this.props.dateFormat,
      firstDay: 1,
      minDate: this.props.minDate,
      // maxDate: this.props.maxDate,
      onChangeMonthYear: this.props.onChangeMonthYear,
      beforeShowDay: this.props.onBeforeShowDay,
    });
    if (this.props.maxDate) {
      window.$(this.datepickerContainer.current).datepicker('option', 'maxDate', new Date(this.props.maxDate));
    }
    window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
  }

  componentDidUpdate() {
    window.$(this.datepickerContainer.current).datepicker('destroy');
    window.$(this.datepickerContainer.current).datepicker({
      onSelect: this.props.onDateChange,
      defaultDate: this.props.initialDate,
      dateFormat: this.props.dateFormat,
      firstDay: 1,
      minDate: this.props.minDate,
      // maxDate: this.props.maxDate,
      onChangeMonthYear: this.props.onChangeMonthYear,
      beforeShowDay: this.props.onBeforeShowDay,
    });
    if (this.props.maxDate) {
      window.$(this.datepickerContainer.current).datepicker('option', 'maxDate', new Date(this.props.maxDate));
    }
    // console.log(this.props.lang)
    if (this.props.lang === 'fi') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
    } else if (this.props.lang === 'en') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
    }
  }

  componentWillUnmount() {
    window.$(this.datepickerContainer.current).datepicker('destroy');
  }

  render() {
    return <div className="DatePicker" id={this.props.id ?? "datepickertest"} ref={this.datepickerContainer} />;
  }
}
