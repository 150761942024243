import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';

function FooterComponent(props) {
  const { t } = useTranslation();
  // const [dateTitle, setDateTitle] = useState({accordianTitle:`${t('LANG_DATE')}`})
  //  console.log(dateTitle)
  return (
    <div className="footer">
      <footer className="text-center text-lg-start">
        <div className="container text-center">
          <a className="powered-by" href="#">
            <img src="https://admin.pregobooking.com/images/logo.svg" />
          </a>

        </div>
      </footer>
    </div>
  );
}

export { FooterComponent };
