import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/config';
import './style.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import reportWebVitals from './reportWebVitals';
import WidgetComponent from './template/WidgetComponent';
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>

          <Route path="/:restaurantId" element={<WidgetComponent />} />

        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
