import React, { Component } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { htmlDecode } from './Utils';
import { LanguageComponent } from './LanguageComponent';
import { GuestComponent } from './GuestComponent';
import { HeaderComponent } from './HeaderComponent';
import { FooterComponent } from './FooterComponent';
import { SelectDateComponent } from './SelectDateComponent';
import { TimeSlotComponents } from './TimeSlotComponent';
import { AreaComponent } from './AreaComponent';
import { ContactDetailsComponent } from './ContactDetailsComponent';
import { EnquiryComponent } from './EnquiryComponent';

function ValidComponent(props) {
  const { isenquiry } = props.settingsData;
  const isContactUsClickedVal = props.settingsData.isContactUsClicked;
  const { t } = useTranslation();
  // poolRestaurantName:pooldata.poolRestaurantName,
  // poolRestaurantDesc:pooldata.poolRestaurantDesc,
  // poolRestaurantLogo:pooldata.poolRestaurantLogo,
  let restaurantName; let restaurantDesc;
  if (props.settingsData.poolRestaurantName) {
    restaurantName = props.settingsData.poolRestaurantName;
  } else {
    restaurantName = props.settingsData.restaurantInfo.name;
  }
  if (props.settingsData.poolRestaurantDesc) {
    restaurantDesc = htmlDecode(props.settingsData.poolRestaurantDesc);
  } else {
    restaurantDesc = htmlDecode(props.settingsData.restaurantInfo.desc);
  }

  let timeslotcom;
  let areaCom;
  let dateCom;
  let reservationCom;
  let enquiryForm;
  let title_txt = `${t('LANG_TITLE')}`;
  if (isenquiry === false) {
    timeslotcom = <TimeSlotComponents data={props} slotChange={props.slotHandler} contactusHandler={props.contactusHandler} isContactClicked={props.isContactUsClickHandler} />;

    dateCom = <SelectDateComponent data={props} dateChange={props.dateHandler} />;
    if (!isContactUsClickedVal) {
      reservationCom = <ContactDetailsComponent data={props} finalSubmit={props.submitHandler} />;
      if (props.settingsData.res_settings.area_path === 'yes') {
        areaCom = <AreaComponent data={props} areaChange={props.areaHandler} />;
      }
      if (i18n.language === 'fi') {
        window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
      } else if (i18n.language === 'en') {
        window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
      }
    }
  } else if (isenquiry === true) {
    enquiryForm = <EnquiryComponent data={props} enquirySubmit={props.enquiryHandler} />;
    title_txt = `${t('LANG_TITLE_ENQUIRY')}`;
  }
  let backtores = false;
  if (parseInt(props.settingsData.defaultRestaurant) !== parseInt(props.settingsData.restaurantId)) {
    backtores = true;
  }

  return (
    <div className="validContainer">
      <HeaderComponent data={props} />
      <div className="container prego-widget-content">
        <div className="row ">
          <div className="main-wrapper">
            { backtores
                        && (
                        <div className="backtohomeres">
                          <a className="backto" href="#" onClick={() => { window.location.reload(false); }}>
                            {'<<'}
                            {' '}
                            {t('LANG_BACK_TO')}
                            {' '}
                            {props.settingsData.defaultRestaurantName}
                          </a>
                        </div>
                        ) }
            <div className="allheader-bar">
              <div className="left rest-title">
                <h3>
                  {' '}
                  {title_txt}
                  {' '}
                  {restaurantName}
                </h3>
              </div>
              <div className=" lang-bar">
                <LanguageComponent />
              </div>
            </div>
            <div className="col-md-11">
              <p dangerouslySetInnerHTML={{ __html: restaurantDesc }} />
            </div>
            <div className="col-md-12 gueter-blaker">
              <div className="clomun-nrt">
                <Accordion preExpanded={['guest-preExpend']}>
                  <GuestComponent data={props} guestChange={props.guestHandler} />
                  {dateCom}
                  {timeslotcom}
                  {areaCom}
                  {reservationCom}
                  {enquiryForm}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent data={props} />
    </div>
  );
}

export { ValidComponent };
