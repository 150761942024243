import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';

function HeaderComponent(props) {
  const { t } = useTranslation();
  let restaurantLogo = props.data.settingsData.restaurantInfo.logoPath;
  if (props.data.settingsData.poolRestaurantLogo) {
    restaurantLogo = props.data.settingsData.poolRestaurantLogo;
  }
  const { iconColor } = props.data.settingsData;

  return (
    <div className="header">
      <nav className="navbar sticky-top navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="#">
            { /* <img src={restaurantLogo} /> */}
          </a>
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto w-100 justify-content-end">
                                <li className="nav-item active">
                                    <a className="nav-link" href="#"> <span className="sr-only"></span></a>
                                </li>
                            </ul>
        </div> */}
        </div>
      </nav>
    </div>

  );
}

export { HeaderComponent };
