import React, { useState } from 'react';
import { htmlDecode } from './Utils';

function SuggestionRestaurantComponent(props) {
  // let activeSlot = props.poolhandler.data.settingsData.poolActiveSlot;
  const suggestionResId = props.renderData.id;
  const suggestionResLogo = props.renderData.logo;
  const suggestionDesc = htmlDecode(props.renderData.long_desc);
  const suggestionResName = props.renderData.name;
  const suggestionSlots = props.renderData.slots;
  const poolData = {
    poolRestaurantId: suggestionResId,
    poolRestaurantName: suggestionResName,
    poolRestaurantDesc: suggestionDesc,
    poolRestaurantLogo: suggestionResLogo,
  };
  const [activeSlot, setActiveSlot] = useState({});
  function selectedPoolSlot(e) {
    window.$('button.poolslot').removeClass('slotActive');
    e.preventDefault();
    const selectslot = e.currentTarget.value;
    poolData.selectedPoolSlot = e.currentTarget.value;
    setActiveSlot({ ...activeSlot, selectslot, selectres: suggestionResId });
    props.selectedSlot(e, props.renderData);
    props.poolhandler(poolData);

    if (props.renderData.settings.area_path === 'yes') {
      setTimeout(() => {
        window.$("[accbtn='areablock']").click();
      }, 700);
    } else {
      window.$("[accbtn='formblock']").click();
    }
  }
  const timeSlotsBtn = suggestionSlots.map((slot) => <button key={slot.time} onClick={selectedPoolSlot} className={`poolslot ${(activeSlot.selectslot === slot.time) && 'slotActive'}`} value={slot.time}>{slot.time}</button>);
  const slotsDom = <div className="content pool slots">{timeSlotsBtn}</div>;
  return (
    <>
      { /* <img src={{suggestionResLogo}} /> */ }
      <div className="pool_restaurant_name">{suggestionResName}</div>
      {suggestionDesc !== '<div><br></div>'
        ? <p className="pool_restauarnt_desc" dangerouslySetInnerHTML={{ __html: suggestionDesc }} />
        : ''}
      {slotsDom}

    </>
  );
}
export default SuggestionRestaurantComponent;
