import React, { Component } from 'react';
import loaderimg from '../images/Prego_Load_Ani.gif';
import loaderimg1 from '../images/prego_ani2.gif';

function LoaderComponent(props) {
  return (
    <div className="content slots">
      <img src={loaderimg1} className="loadercenter" alt="fetching slots" />
    </div>
  );
}

export default LoaderComponent;
