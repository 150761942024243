import { useTranslation } from 'react-i18next';

function LanguageComponent() {
  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    if (e.target.value === 'fi') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
    } else if (e.target.value === 'en') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
    }
  }

  return (
    <select className="selectpicker" onChange={changeLanguage}>

      <option data-content='<span className="flag-icon flag-icon-mx"></span> Finish' value="fi">FI </option>
      <option data-content='<span className="flag-icon flag-icon-us"></span> English' value="en">EN </option>
    </select>
  );
}

export { LanguageComponent };
