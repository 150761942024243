import React, { Component } from 'react';

const htmlDecode = function (text) {
  if (text) {
    const map = {
      '&amp;': '&',
      '&#038;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
      '&#8217;': '’',
      '&#8216;': '‘',
      '&#8211;': '–',
      '&#8212;': '—',
      '&#8230;': '…',
      '&#8221;': '”',
    };

    return text.replace(/\&[\w\d\#]{2,5}\;/g, (m) => map[m]);
  }
  return '';
};

export { htmlDecode };
