import React, { Component } from 'react';
import '../invalidrestaurant.css';

function InvalidComponent() {
  return (
    <div id="noRestaurant">
      <div className="face">
        <div className="band">
          <div className="red" />
          <div className="white" />
          <div className="blue" />
        </div>
        <div className="eyes" />
        <div className="dimples" />
        <div className="mouth" />
      </div>

      <h1>Oops! No Restaurants available!</h1>
    </div>
  );
}

export { InvalidComponent };
