export default function validate(values) {
  const errors = {};
  if (!values.contact_first_name) {
    errors.contact_first_name = 'Enter firt name';
  }
  if (!values.contact_last_name) {
    errors.contact_last_name = 'Enter last name';
  }
  if (!values.contact_email) {
    errors.contact_email = 'Enter email';
  } else if (!/\S+@\S+\.\S+/.test(values.contact_email)) {
    errors.contact_email = 'Email address is invalid';
  }
  if (!values.phone_number) {
    errors.phone_number = 'Enter phone number';
  } else if (values.phone_number.length > 12) {
    errors.phone_number = 'Phone number can not enter morthan 12';
  }

  return errors;
}
