import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import InputSpinner from 'react-bootstrap-input-spinner';

import GroupIcon from '@mui/icons-material/GroupOutlined';

function GuestComponent(props) {
  const { t } = useTranslation();
  const [guestCount, setGuestCount] = useState(0);
  const enquiryHint = props.data.settingsData.isenquiry;
  const max_group_size_e = props.data.settingsData.res_settings.max_group_size;
  const max_group_size = Number(props.data.settingsData.res_settings.max_group_size) + Number(1);
  const { iconColor } = props.data.settingsData;
  let enquiryHintMsg;
  if (enquiryHint) {
    const enquiryHintTxt = `${t('LANG_ENQUIRY_HINT')}`.replace('$MAX_SIZE$', max_group_size_e);
    enquiryHintMsg = <div style={{ color: '#f7726e' }}>{enquiryHintTxt}</div>;
  }

  useEffect(() => {
    if (guestCount === 0) {
      setGuestCount(2)
      guestChangeHandler(2)
    }
  }, [])

  function guestChangeHandler(selectedGuest) {
    let accordianTitle;
    if (Number(selectedGuest) > 1) {
      accordianTitle = `${selectedGuest} ` + `${t('LANG_GUESTS')}`;
    } else if (Number(selectedGuest) == 1) {
      accordianTitle = `${selectedGuest} ` + `${t('LANG_GUEST')}`;
    } else {
      accordianTitle = `${t('LANG_HOW_MANY_GUEST')}`;
    }
    setGuestCount(Number(selectedGuest));
    props.guestChange(selectedGuest);
  }
  let titleTxt = `${t('LANG_HOW_MANY_GUEST')}`;
  if (guestCount == 1) {
    titleTxt = `${guestCount} ` + `${t('LANG_GUEST')}`;
  } else if (guestCount > 1) {
    titleTxt = `${guestCount} ` + `${t('LANG_GUESTS')}`;
  }

  return (
    <AccordionItem uuid="guest-preExpend">
      <AccordionItemHeading>
        <AccordionItemButton>
          <GroupIcon sx={{ color: iconColor }} />
          <i className="fa-solid fa-user" />
          <span className="guests-opl">{titleTxt}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className="content guests">
          <InputSpinner
            type="int"
            min={1}
            max={max_group_size}
            editable={false}
            step={1}
            value={2}
            onChange={(num) => guestChangeHandler(num)}
          />
          {enquiryHintMsg}
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export { GuestComponent };
