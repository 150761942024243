import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fi'
import {
  AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import CalendarDaysIcon from '@mui/icons-material/CalendarTodayOutlined';
import { DatePicker } from './DatePicker';

function SelectDateComponent(props) {
  const { t } = useTranslation();
  const { iconColor } = props.data.settingsData;
  const {selectedDate} = props.data.settingsData;
  const d = props.data.settingsData.adanceBooking.days.advance_booking_day.split('/');
  const maxdate = new Date(d);

  function dateSelect(date) {
    window.$("[accbtn='slotblock']").click();
    props.dateChange(date);
  }

  let dateTitle = `${t('LANG_DATE')}`;
  if (selectedDate) {
    const dateSplit = selectedDate.split('.');
    const dateObj = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
    const accordianTitle = moment(dateObj).locale(i18n.language).format('dddd DD.MM.YYYY');
    dateTitle = accordianTitle.charAt(0).toUpperCase()
    + accordianTitle.slice(1);
  }

  function monthChange() {
    if (i18n.language === 'fi') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
    } else if (i18n.language === 'en') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
    }
  }

  function beforeShowDay(date) {
    const notesData = props.data.settingsData.dailyNotes;
    const SelectedDates = [];
    const SeletedText = [];
    const d = props.data.settingsData.adanceBooking.days.advance_booking_day;
    const maxdate = new Date(d).getTime();
    window.$('.ui-datepicker-next').removeClass('d-none');

    if (maxdate < new Date(date).getTime()) {
      window.$('.ui-datepicker-next').addClass('d-none');
      return false;
    }

    if (notesData) {
      Object.keys(notesData).map((key, value) => {
        SelectedDates[new Date(key)] = new Date(key).toString();
        const keyval = new Date(key);
        if (keyval in SeletedText) {

        } else {
          SeletedText[keyval] = '';
        }
        const noteArr = notesData[key];
        const inval = 1;
        noteArr.note.forEach((noteTxt, indexKey) => {
          if (indexKey >= 1) {
            SeletedText[new Date(key)] += `<div class="noteindex">Note ${inval + indexKey} : </div><div class="notetooltip">${noteTxt}</div></br>`;
          } else {
            SeletedText[new Date(key)] += `<div class="noteindex">Note: </div><div class="notetooltip">${noteTxt}</div></br>`;
          }
        });
      });
    }

    const Highlight = SelectedDates[date];
    const HighlighText = SeletedText[date];
    if (Highlight) {
      return [true, 'Highlighted', HighlighText];
    }
    return [true, '', ''];
  }
  window.$('.ui-tooltip').remove();
  window.$(document, '.Highlighted').tooltip({
    content() {
      return window.$(this).prop('title');
    },
  });

  return (
    <AccordionItem uuid="-">
      <AccordionItemHeading>
        <AccordionItemButton accbtn="dateblock">
          <CalendarDaysIcon sx={{ color: iconColor }} />
          <span className="guests-opl">{dateTitle}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className={`${props.activeTag === 'tab2' ? 'active' : ''} content`}>
          <div id="calendar">
            <DatePicker
              onDateChange={dateSelect}
              onBeforeShowDay={beforeShowDay}
              initialDate={props.data.settingsData.selectedDate}
              dateFormat="dd.mm.yy"
              minDate={new Date()}
              maxDate={maxdate || ''}
              onChangeMonthYear={monthChange}
              lang={i18n.language}
            />
          </div>
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export { SelectDateComponent };
