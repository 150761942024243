import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import { proposalSyntaxPlugins } from '@babel/preset-env/lib/shipped-proposals';
// import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MailIcon from '@mui/icons-material/MailOutlined';
import DatePicker from 'react-datepicker';
import i18n from 'i18next';
import moment from 'moment';
import IntlTelInput, { IntlTelInputProps, CountryData } from 'react-intl-tel-input';
import validate from './widgetForm/EnquiryFormValidationRules';
import useForm from './widgetForm/useForm';
import 'react-intl-tel-input/dist/main.css';

import 'react-datepicker/dist/react-datepicker.css';

export function EnquiryComponent(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    no_of_guests: '',
    enquiry_date: '',
    start_time: '',
    duration: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number_code: '',
    phone_number: '',
    special_request: '',
    promocode: '',
    newsletter: '',
    agreecheckbox: '',
    res_newsletter: '',
    fullphonenumber: '',
  });
  const [startDate, setStartDate] = useState();
  const [enquiryDate, setEnquiryDate] = useState();
  const [fullphonenumber, setFullphonenumber] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [phonenumber_code, setPhoneCode] = useState();
  const { iconColor } = props.data.settingsData;
  const inputRef = useRef([]);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
  } = useForm(formSubmit, validate);
  function datePickerHandle(date) {
    setStartDate(date);
    setData({ ...data, enquiry_date: date });
  }
  function formHandle(e) {
    const newData = { ...data };
    newData.enquiry_date = enquiryDate;
    newData.phone_number = phone_number;
    newData.phone_number_code = phonenumber_code;
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        newData[e.target.id] = e.target.value;
      }
    } else {
      newData[e.target.id] = e.target.value;
    }
    setData(newData);

    handleChange(e);
  }
  function setDateValue(date) {
    const momDateObj = moment(date);
    const formatedate = momDateObj.format('DD.MM.YYYY');
    const dynamicEvent = { event: { target: { name: 'enquiry_date', value: formatedate } } };
    setEnquiryDate(formatedate);
    setValues((values) => ({ ...values, enquiry_date: formatedate }));
    setStartDate(date);
  }
  function formSubmit() {
    props.enquirySubmit(data);
  }
  const preventNegative = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const phoneFieldHandle = (isValid, value, selectedCountryData, fullNumber, extension) => {
    const nextValue = isValid
      ? fullNumber.replace(/\s|-/g, '')
      : value;
    const ccode = `+${selectedCountryData.dialCode}`;
    setPhoneCode(ccode);
    console.log();
    setFullphonenumber(fullNumber);
    setValues((values) => ({ ...values, phone_number: nextValue.replace(ccode, '') }));
    setPhoneNumber(nextValue.replace(ccode, ''));
    if (nextValue == '' || nextValue == null) {
      inputRef.current.phone_number.classList.remove('phone-focus');
    } else {
      inputRef.current.phone_number.classList.add('phone-focus');
    }
    const newData = { ...data };
    newData.phone_number = nextValue;
    newData.fullphonenumber = fullNumber;
    newData.phone_number_code = `+${selectedCountryData.dialCode}`;
    newData.phone_number = nextValue;
    setData(newData);
  };

  const handleSelectFlag = (currentNumber, seletedCountryData, fullNumber, isValid) => {
    const ccode = seletedCountryData.dialCode;
    setPhoneCode('+', ccode);
  };

  function adjustPlaceholder(e, inpoutfield) {
    if (inpoutfield == 'phone_number') {
      inputRef.current[inpoutfield].classList.add('phone-focus');
      document.querySelector('.phone-numer-wth-code').focus();
    } else if (inpoutfield == 'enquiry_date') {
      inputRef.current[inpoutfield].classList.add('date-focus');
      document.querySelector('#enquiry_date').focus();
    } else {
      inputRef.current[inpoutfield].focus();
    }
  }
  const handlePhoneFocusLost = (e, element) => {
    if (element == 'phone_number') {
      inputRef.current.phone_number.classList.remove('phone-focus');
    } else if (element == 'enquiry_date') {
      // console.log("AS");
      inputRef.current.enquiry_date.classList.remove('date-focus');
    }
  };

  let duration;
  if (props.data.settingsData.selectedLanguage == 'en') {
    duration = ['1 hour', '1.5 hours', '2 hours', '2.5 hours', '3 hours', '3.5 hours', '4 hours', '4.5 hours', '5 hours', '5.5 hours', '6 hours', '6.5 hours', '7 hours', '7.5 hours', '8 hours', '8.5 hours', '9 hours', '9.5 hours', '10 hours'];
  } else if (props.data.settingsData.selectedLanguage == 'fi') {
    duration = ['1 tunti', '1.5 tuntia', '2 tuntia', '2.5 tuntia', '3 tuntia', '3.5 tuntia', '4 tuntia', '4.5 tuntia', '5 tuntia', '5.5 tuntia', '6 tuntia', '6.5 tuntia', '7 tuntia', '7.5 tuntia', '8 tuntia', '8.5 tuntia', '9 tuntia', '9.5 tuntia', '10 tuntia'];
  }
  const durationOption = [];
  durationOption.push(<option value="" />);
  for (let d = 0; d < duration.length; d++) {
    durationOption.push(<option key={duration[d]} value={duration[d]}>{duration[d]}</option>);
  }

  // start time
  const x = 30; // minutes interval
  const times = []; // time array
  let tt = 0; // start time
  const startTimeDom = [];
  startTimeDom.push(<option value="" />);
  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = (tt % 60); // getting minutes of the hour in 0-55 format
    times[i] = `${(`0${hh % 24}`).slice(-2)}:${(`0${mm}`).slice(-2)}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt += x;
  }
  times.forEach((val) => {
    startTimeDom.push(<option key={val} value={val}>{val}</option>);
  });
  const disabledBtn = props.data.settingsData.isSubmitClicked ? 'disabled' : false;

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <MailIcon sx={{ color: iconColor }} />
          <span className="guests-opl">{t('LANG_ENQUIRY_FORM')}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <form autoComplete="off" name="widgetcontact" id="widgetcontactform" onSubmit={handleSubmit} noValidate>
          <div className=" content">
            <div className="divider-field-wrapper">
              <div className={`${values?.no_of_guests ? 'hasValue' : ''} field-wrapper `} onClick={(e) => adjustPlaceholder(e, 'no_of_guests')}>
                <input ref={(el) => inputRef.current.no_of_guests = el} min={1} onKeyPress={preventNegative} className={`${errors.no_of_guests && 'isdanger'}`} onChange={(e) => formHandle(e)} type="number" id="no_of_guests" name="no_of_guests" value={values.no_of_guests || ''} />
                <div className="field-placeholder">
                        {t('LANG_NO_GUEST')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>

              </div>
              <div ref={(el) => inputRef.current.enquiry_date = el}>
                <div className={`${values?.enquiry_date ? 'hasValue' : ''} field-wrapper  field-wrapper2`} onClick={(e) => adjustPlaceholder(e, 'enquiry_date')}>
                        <div id="calendar"><DatePicker onFocus={(e) => adjustPlaceholder(e, 'enquiry_date')} onBlur={(e) => handlePhoneFocusLost(e, 'enquiry_date')} ref={(el) => inputRef.current.enquiry_date = el} className={`${errors.enquiry_date && 'isdanger'}`} id="enquiry_date" name="enquiry_date" dateFormat="dd.MM.yyyy" minDate={new Date()} calendarStartDay={1} selected={startDate} onChange={(date) => setDateValue(date)} /></div>
                        <div className="field-placeholder floating-label-date">
                            {t('LANG_DATE')}
                            {' '}
                            <sup>*</sup>
                            {' '}
                          </div>
                      </div>
              </div>
            </div>
            <div className="divider-field-wrapper">
              <div className={`${values?.start_time ? 'hasValue' : ''} field-wrapper `} onClick={(e) => adjustPlaceholder(e, 'start_time')}>
                <select ref={(el) => inputRef.current.start_time = el} id="start_time" onChange={(e) => formHandle(e)} className={`form-select ${errors.start_time && 'isdangerfull'}`} name="start_time">
                        {startTimeDom}
                      </select>
                <div className="field-placeholder">
                        {t('LANG_START_TIME')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
              <div className={`${values?.duration ? 'hasValue' : ''} field-wrapper  field-wrapper2`} onClick={(e) => adjustPlaceholder(e, 'duration')}>
                <select ref={(el) => inputRef.current.duration = el} id="duration" onChange={(e) => formHandle(e)} className={`form-select ${errors.duration && 'isdangerfull'}`} name="duration">
                        {durationOption}
                      </select>
                <div className="field-placeholder">
                        {t('LANG_DURATION')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
            </div>
            <div className="divider-field-wrapper">
              <div className={`${values?.first_name ? 'hasValue' : ''} field-wrapper `} onClick={(e) => adjustPlaceholder(e, 'first_name')}>
                <input ref={(el) => inputRef.current.first_name = el} className={`${errors.first_name && 'isdanger'}`} onChange={(e) => formHandle(e)} type="text" id="first_name" name="first_name" value={values.first_name || ''} />
                <div className="field-placeholder">
                        {t('LANG_FIRTNAME')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
              <div className={`${values?.last_name ? 'hasValue' : ''} field-wrapper  field-wrapper2`} onClick={(e) => adjustPlaceholder(e, 'last_name')}>
                <input ref={(el) => inputRef.current.last_name = el} className={`${errors.last_name && 'isdanger'}`} onChange={(e) => formHandle(e)} type="text" name="last_name" id="last_name" />
                <div className="field-placeholder">
                        {t('LANG_LASTNAME')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
            </div>
            <div className="divider-field-wrapper1">
              <div className={`${values?.email ? 'hasValue' : ''} field-wrapper `} onClick={(e) => adjustPlaceholder(e, 'email')}>
                <input ref={(el) => inputRef.current.email = el} className={`${errors.email && 'isdanger'}`} onChange={(e) => formHandle(e)} type="email" name="email" id="email" />
                <div className="field-placeholder">
                        {t('LANG_EMAIL')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
            </div>
            <div className="divider-field-wrapper" ref={(el) => inputRef.current.phone_number = el}>
              <div className={`${values?.phone_number ? 'hasValue' : ''} field-wrapper ` + `${errors.phone_number && 'isdangerfull-phone'}`}>
                <IntlTelInput
                        onPhoneNumberBlur={(e) => handlePhoneFocusLost(e, 'phone_number')}
                        fieldName="phone_number"
                        containerClassName="intl-tel-input"
                        inputClassName="phone-numer-wth-code form-control "
                        defaultCountry="fi"
                        placeholder=""
                        formatOnInit="true"
                        onPhoneNumberChange={phoneFieldHandle}
                        onSelectFlag={handleSelectFlag}
                        onPhoneNumberFocus={(e) => adjustPlaceholder(e, 'phone_number')}
                        separateDialCode="false"
                      />
                <div className="field-placeholder floating-label" onClick={(e) => adjustPlaceholder(e, 'phone_number')}>
                        {t('LANG_PHONE_NUMBER')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
              {/* <div className="field-wrapper">
                            <CountryDropdown  id="phone_number_code" name="phone_number_code" className='YOUR_CSS_CLASS' preferredCountries={['in', 'fi']}  value="" handleChange={e => console.log(e)}></CountryDropdown>
                            </div>
                            <div className="field-wrapper field-wrapper-next3">
                                <input className={`${errors.phone_number && 'isdanger'}`} onChange={e=>formHandle(e)} name="phone_number" type="tel" id="phone_number"/>
                                <div className="field-placeholder">Phone <sup>*</sup> </div>
                            </div> */}
            </div>
            <h2 className="Additional-info234">{t('LANG_ADDITIONAL_INFO')}</h2>
            <div className="divider-field-wrapper1">
              <div className="field-wrapper">
                <textarea onChange={(e) => formHandle(e)} name="special_request" id="special_request" />
                <div className="field-placeholder">
                        {t('LANG_SPECIAL_NEEDS')}
                        {' '}
                      </div>
              </div>
            </div>

            <div className="divider-field-wrapper">
              
             
              
              <div className="form-group-nature">
                <input onChange={(e) => formHandle(e)} type="checkbox" id="res_newsletter" name="res_newsletter" />
                <label htmlFor="res_newsletter">
                        <span>
                            {t('LANG_RES_NEWSLETTER')}
                            {' '}
                            <b>{props.data.settingsData.restaurantInfo.name}</b>
                          </span>
                      </label>
              </div>
              {/*
              <div className="form-group-nature">
                <input className={`${errors.newsletter && 'isdanger'}`} onChange={(e) => formHandle(e)} type="checkbox" id="newsletter" name="newsletter" />
                <label htmlFor="newsletter"><span>{t('LANG_NEWSLETTER')}</span></label>
              </div>

              */}

              <div className="form-group-nature">
                <input className={`${errors.agreecheckbox && 'isdanger'}`} onChange={(e) => formHandle(e)} type="checkbox" id="agreecheckbox" name="agreecheckbox" />
                <label htmlFor="agreecheckbox">
                        {t('LANG_TERMS_CONDITION_TEXT_1')}
                        {' '}
                        <a style={{ color: iconColor }} href={i18n.language === 'en' ? process.env.REACT_APP_PREGO_TERMS_CONDITION_EN : process.env.REACT_APP_PREGO_TERMS_CONDITION_FI} target="_blank" rel="noreferrer">{t('LANG_TERMS_CONDITION_TEXT_2')}</a>
                        {' '}
                        {t('LANG_TERMS_CONDITION_TEXT_3')}
                        {' '}
                        <a style={{ color: iconColor }} target="_blank" href={i18n.language === 'en' ? process.env.REACT_APP_PREGO_TERMS_CONDITION_EN : process.env.REACT_APP_PREGO_TERMS_CONDITION_FI} rel="noreferrer">{t('LANG_TERMS_CONDITION_TEXT_4')}</a>
                        {' '}
                        <sup>*</sup>
                      </label>
              </div>
            </div>
            <div className="divider-field-wrapper">
              <input type="submit" value={t('LANG_BTN_SEND_ENQUIRY')} style={{ backgroundColor: iconColor }} className="sender-but" disabled={disabledBtn} />
            </div>
          </div>
        </form>
      </AccordionItemPanel>
    </AccordionItem>
  );
}
