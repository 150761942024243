import React from 'react';
import i18n from 'i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { InvalidComponent } from './InvalidComponent';
import { ValidComponent } from './ValidComponent';
import 'react-accessible-accordion/dist/fancy-example.css';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

const todayDate = moment();
let restaurantWidgetComponent;

const newStagingRestaurantIds = [];

const getApiEndpoint = (restaurantId) => {
  // ${process.env.REACT_APP_API_ENDPOINT};
  if(newStagingRestaurantIds.includes(restaurantId)) {
    return "https://pregostaging.kemmelby.com/api/v1";
  } else {
    return "https://admin.pregobooking.com/api/v1";
  }
}

const initalState = {
  selectedDate: '',
  selectedGuest: '',
  selectedArea: '',
  activeSlot: '',
  selectedSlot: '',
  activeArea: '',
  poolActiveRes: '',
  poolActiveSlot: '',
  poolRestaurantName: '',
  poolRestaurantDesc: '',
  poolRestaurantLogo: '',
  isenquiry: false,
  configData: [],
  postData: [],
  timeSlots: [],
  areaList: [],
  iconColor: '#004c45',
  showArea: true,
  showTimeSlot: false,
  showSuggestionMsg_1: false,
  showSuggestionMsg_2: false,
  suggestionmsg_1: 'Show suggestion',
  suggestionmsg_2: 'no time available',
  isGuestSelected: false,
  isDateSelected: false,
  isSlotSelected: false,
  isAreaSelected: false,
  dailyNotes: {},
  adanceBooking: {},
  selectedLanguage: i18n.language,
  reservationSubmitted: false,
  reservationHasError: false,
  isSubmitClicked: false,
  contactusSubmitted: false,
  isContactUsClicked: false,
  errorMsg: '',
  isvalid: '',
  backtores: false,
  defaultRestaurant: '',
  defaultRestaurantName: '',
  showLoaderInSlot: true,
  suggested_by: '',
  journey: [],

};
// let isRestaurantValid = '';
class WidgetComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initalState,
    };
    this.guestChange = this.guestChange.bind(this);
    this.dateSelect = this.dateSelect.bind(this);
    this.selectedSlot = this.selectedSlot.bind(this);
    this.selectedArea = this.selectedArea.bind(this);
    this.finalSubmitForm = this.finalSubmitForm.bind(this);
    this.fetchSlot = this.fetchSlot.bind(this);
    this.enquirySubmit = this.enquirySubmit.bind(this);
    this.contactUsClicked = this.contactUsClicked.bind(this);
    this.props.params.restaurantId = (this.props.params.restaurantId).split('-').pop();
  }

  // reset state
  resetState() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {});
    this.setState({ ...stateReset, ...initalState }, function () {
      this.fetchConfig();
    });
  }

  // On load page
  componentDidMount() {
    // Simple GET request using axios
    this.fetchConfig();
  }

  componentDidUpdate() {

  }

  // CONFIG api
  fetchConfig() {
    axios.post(
      `${getApiEndpoint(this.props.params.restaurantId)}/widget/coreConfig`,
      {
        // this.props.params.restaurantId
        restaurant_id: (this.state.restaurantId ? this.state.restaurantId : this.props.params.restaurantId),
        refer: 'config',
      },
    ).then((response) => {
      const responseData = response.data.data;
      this.setState({
        defaultRestaurant: this.props.params.restaurantId,
        restaurantId: (this.state.restaurantId ? this.state.restaurantId : this.props.params.restaurantId),
        isvalid: responseData.isValid,
        restaurantInfo: responseData.restaurantInfo,
        res_settings: responseData.settings,
        dailyNotes: responseData.dailyNotes,
        adanceBooking: responseData.advanceBooking,
        iconColor: responseData.settings?.widget_brand_color,
        defaultRestaurantName: this.state.defaultRestaurantName === '' ? responseData.restaurantInfo.name : this.state.defaultRestaurantName,

      }, () => {
        if (i18n.language === 'fi') {
          window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
        } else if (i18n.language === 'en') {
          window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
        }
      });
    });
  }

  // fetch slot
  fetchSlot() {
    const timeSlots = [];
    axios.post(
      `${getApiEndpoint(this.props.params.restaurantId)}/widget/getSlots`,
      {
        restaurant_id: (this.state.restaurantId ? this.state.restaurantId : this.props.params.restaurantId),
        date: this.state.selectedDate,
        no_of_guests: this.state.selectedGuest,
      },
    ).then((response) => {
      const showSlots = response.data.showTimeSlots;
      const showSuggestion = response.data.showSuggestionMsg_1;
      const noSlots = response.data.showSuggestionMsg_2;
      const slotsData = response.data.data;

      if (showSlots === true) {
        for (const [key, value] of Object.entries(slotsData)) {
          const slotsValue = slotsData[key].time;
          timeSlots.push(slotsValue);
        }
        this.setState({
          timeSlots,
          showTimeSlot: true,
          showSuggestionMsg_1: false,
          showSuggestionMsg_2: false,
          activeSlot: '',
          poolActiveSlot: '',
          selectedSlot: '',
          isContactUsClicked: false,
          showLoaderInSlot: false,
        });
      } else if (showSuggestion === true) {
        this.setState({
          showTimeSlot: false,
          showSuggestionMsg_1: true,
          showSuggestionMsg_2: false,
          activeSlot: '',
          poolActiveSlot: '',
          selectedSlot: '',
          isContactUsClicked: false,
          showLoaderInSlot: false,
        });
      } else if (noSlots === true) {
        this.setState({
          showTimeSlot: false,
          showSuggestionMsg_1: false,
          showSuggestionMsg_2: true,
          activeSlot: '',
          poolActiveSlot: '',
          selectedSlot: '',
          isContactUsClicked: false,
          showLoaderInSlot: false,
        });
      }
    });
  }

  // fetch available areas
  fetchAreas() {
    const areaLit = [];
    axios.post(
      `${getApiEndpoint(this.props.params.restaurantId)}/widget/getAvailableAreas`,
      {
        restaurant_id: (this.state.restaurantId ? this.state.restaurantId : this.props.params.restaurantId),
        date: this.state.selectedDate,
        no_of_guests: this.state.selectedGuest,
        slotsTime: (this.state.poolActiveSlot) ? this.state.poolActiveSlot : this.state.selectedSlot,
      },
    ).then((response) => {
      const responseData = response.data.data;
      this.setState({
        areaList: responseData,
        activeArea: '',
        selectedArea: '',
      });
    });
  }

  // Guest change method
  guestChange(noOfguest) {
    this.setState({
      selectedGuest: noOfguest,
      isGuestSelected: true,
      showLoaderInSlot: true,
    }, function () {
      if (this.state.isDateSelected) {
        this.fetchSlot();
      }
    });
    const max_group_size = this.state?.res_settings?.max_group_size;
    if (Number(noOfguest) > Number(max_group_size)) {
      this.setState({
        isenquiry: true,
      });
    } else {
      this.setState({
        isenquiry: false,
      });
    }
    if (i18n.language === 'fi') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
    } else if (i18n.language === 'en') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
    }
  }

  // Date select method
  dateSelect(date) {
    this.setState({
      selectedDate: date,
      isDateSelected: true,
      showLoaderInSlot: true,
      activeArea: '',
      selectedArea: '',
    }, function () {
      if (this.state.isGuestSelected && this.state.isDateSelected) {
        this.fetchSlot();
      }
    });
    if (i18n.language === 'fi') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.fi);
    } else if (i18n.language === 'en') {
      window.$.datepicker.setDefaults(window.$.datepicker.regional.en);
    }
  }

  // Slot select method
  selectedSlot = (e) => {
    const slot = `${e.currentTarget.value}`;
    this.setState({
      activeSlot: slot,
      selectedSlot: slot,
    }, function () {
      this.fetchAreas();
    });
  };

  // Area selected method
  selectedArea = (e) => {
    const areaId = `${e.currentTarget.value}`;
    this.setState({
      activeArea: areaId,
      selectedArea: areaId,
    });
  };

  // Final Submition
  finalSubmitForm = (formdata) => {
    this.setState({
      isSubmitClicked: true,
    });
    formdata.restaurant_id = this.state.restaurantId;
    formdata.reservationDate = this.state.selectedDate;
    formdata.no_of_guests = this.state.selectedGuest;
    formdata.slotsTime = (this.state.poolActiveSlot) ? this.state.poolActiveSlot : this.state.selectedSlot;
    formdata.area_id = this.state.selectedArea;
    formdata.lang_code = i18n.language;
    axios.post(`${getApiEndpoint(this.props.params.restaurantId)}/widget/createReservation`, formdata)
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            isSubmitClicked: false,
            reservationSubmitted: true,
            reservationHasError: false,
          });
        }
      }, (error) => {
        const errorMsg = error.response.data.message;
        this.setState({
          isSubmitClicked: false,
          reservationSubmitted: true,
          reservationHasError: true,
          errorMsg,
        });
      });
  };

  // ENQUIRY SUBMITION
  enquirySubmit = (formdata) => {
    this.setState({
      isSubmitClicked: true,
    });
    formdata.restaurant_id = this.state.restaurantId;
    formdata.reservationDate = formdata.enquiry_date;
    formdata.no_of_guests = formdata.no_of_guests ? formdata.no_of_guests : this.state.selectedGuest;
    formdata.lang_code = i18n.language;
    axios.post(`${getApiEndpoint(this.props.params.restaurantId)}/widget/createEnquiry`, formdata)
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            isSubmitClicked: false,
            enquirySubmitted: true,
            enquiryHasError: false,
          });
        }
      }, (error) => {
        const errorMsg = error.response.data.message;
        this.setState({
          isSubmitClicked: false,
          enquirySubmitted: true,
          enquiryHasError: true,
          errorMsg,
        });
      });
  };

  // CONTACTUS SUBMITION
  contactusSubmit = (formdata) => {
    this.setState({
      isSubmitClicked: true,
    });
    formdata.restaurant_id = this.state.restaurantId;
    formdata.contact_date = this.state.selectedDate;
    formdata.no_of_guests = this.state.selectedGuest;
    axios.post(`${getApiEndpoint(this.props.params.restaurantId)}/widget/sendContactUs`, formdata)
      .then((response) => {
        this.setState({
          isSubmitClicked: false,
          reservationSubmitted: false,
          reservationHasError: false,
          contactusSubmitted: true,
        });
      }, (error) => {
        this.setState({
          isSubmitClicked: false,
          reservationSubmitted: false,
          reservationHasError: false,
          contactusSubmitted: true,
        });
      });
  };

  // POOL SLOTS CLICK HANDLER
  poolSlotsHandler = (pooldata) => {
    // Capture previous state of suggested by restaurant
    if (!this.state.journey) {
      var journey = parseInt(this.props.params.restaurantId);
      var suggested_by = this.props.params.restaurantId;
    } else {
      var suggested_by = this.state.restaurantId;
      var journey = parseInt(this.state.restaurantId);
    }

    this.setState({
      suggested_by,
      journey: this.state.journey.concat(journey),
    });
    this.setState({
      poolActiveRes: pooldata.poolRestaurantId,
      restaurantId: pooldata.poolRestaurantId,
      poolActiveSlot: pooldata.selectedPoolSlot,
      showSuggestionMsg_1: false,
      showSuggestionMsg_2: false,
      showTimeSlot: false,
      poolRestaurantName: pooldata.poolRestaurantName,
      poolRestaurantDesc: pooldata.poolRestaurantDesc,
      poolRestaurantLogo: pooldata.poolRestaurantLogo,
    }, function () {
      this.fetchConfig();
      this.fetchAreas();
    });
  };

  // is contactus clicked
  contactUsClicked = () => {
    this.setState({
      isContactUsClicked: true,
    });
  };

  swlConfirm = () => {
    window.parent.location = this.state?.res_settings?.widget_back_url;
    // window.location =this.state?.res_settings?.widget_back_url;
    // window.location.reload(false);
    // this.setState({
    //     reservationSubmitted:false,
    // });
    // this.resetState();
  };

  render() {
    const isRestaurantValid = this.state?.isvalid;
    if (isRestaurantValid === 'valid') {
      restaurantWidgetComponent = (
        <ValidComponent
          settingsData={this.state}
          guestHandler={this.guestChange}
          dateHandler={this.dateSelect}
          slotHandler={this.selectedSlot}
          areaHandler={this.selectedArea}
          submitHandler={this.finalSubmitForm}
          poolHandler={this.poolSlotsHandler}
          enquiryHandler={this.enquirySubmit}
          contactusHandler={this.contactusSubmit}
          isContactUsClickHandler={this.contactUsClicked}
        />
      );
    } else if (isRestaurantValid === 'notvalid') {
      restaurantWidgetComponent = <InvalidComponent />;
    }
    return (
      <div>
        {restaurantWidgetComponent}
        {this.state.reservationSubmitted && !this.state.reservationHasError && (
        <SweetAlert
          success
          title={`${this.props.t('LANG_ALERT_1')}`}
          showCloseButton
          confirmBtnBsStyle="link"
          onConfirm={this.swlConfirm}
          closeOnClickOutside={false}
        />
        )}
        {this.state.reservationSubmitted && this.state.reservationHasError && (
        <SweetAlert
          error
          title={this.state.errorMsg}
          showCloseButton
          confirmBtnBsStyle="link"
          onConfirm={() => {
            this.setState({
              isSubmitClicked: false,
              reservationSubmitted: false,
              reservationHasError: false,
              errorMsg: '',
            });
          }}
          closeOnClickOutside={false}
        />
        )}
        {
                this.state.enquirySubmitted && !this.state.enquiryHasError && (
                <SweetAlert
                  success
                  title={`${this.props.t('LANG_ALERT_2')}`}
                  confirmBtnBsStyle="link"
                  showCloseButton
                  onConfirm={this.swlConfirm}
                  closeOnClickOutside={false}
                />
                )
            }
        {
                this.state.enquirySubmitted && this.state.enquiryHasError && (
                <SweetAlert
                  error
                  title={this.state.errorMsg}
                  confirmBtnBsStyle="link"
                  showCloseButton
                  onConfirm={() => {
                    this.setState({
                      isSubmitClicked: false,
                      enquirySubmitted: false,
                      enquiryHasError: false,
                      errorMsg: '',
                    });
                  }}
                  closeOnClickOutside={false}
                />
                )
            }
        {
                this.state.contactusSubmitted && (
                <SweetAlert
                  success
                  title={`${this.props.t('LANG_ALERT_3')}`}
                  confirmBtnBsStyle="link"
                  showCloseButton
                  onConfirm={this.swlConfirm}
                  closeOnClickOutside={false}
                />
                )
            }
      </div>
    );
  }
}
// Route params
function withParams(Component) {
  return function (props) {
    return <Component {...props} params={useParams()} />;
  };
}

const widgetComponentParam = withParams(WidgetComponent);
export default withTranslation()(widgetComponentParam);
