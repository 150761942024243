import React, { Component, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import MailIcon from '@mui/icons-material/MailOutlined';
import IntlTelInput, { IntlTelInputProps, CountryData } from 'react-intl-tel-input';
import useForm from './widgetForm/useForm';
import validate from './widgetForm/WidgetFormValidationRules';
import 'react-intl-tel-input/dist/main.css';
import i18n from 'i18next';

export function ContactDetailsComponent(props) {
  const { t } = useTranslation();
  const [clientError, setClientError] = useState();
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number_code: '',
    phone_number: '',
    fullphonenumber: '',
    special_request: '',
    promocode: '',
    newsletter: '',
    agreecheckbox: '',
    res_newsletter: '',
    suggested_by: '',
    journey: '',

  });
  const [phonenumberval, setPhoneNumberval] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [fullphonenumber, setFullphonenumber] = useState();

  const [phonenumber_code, setPhoneCode] = useState();
  const { iconColor } = props.data.settingsData;
  const disabledBtn = props.data.settingsData.isSubmitClicked ? 'disabled' : false;
  const inputRef = useRef([]);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
  } = useForm(formSubmit, validate);

  function formHandle(e) {
    const newData = { ...data };
    console.log(newData);
    const checkboxIds = ['newsletter', 'agreecheckbox'];
    newData.phone_number = phone_number;
    newData.phone_number_code = phonenumber_code;
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        newData[e.target.id] = e.target.value;
      }
    } else {
      newData[e.target.id] = e.target.value;
    }
    newData.suggested_by = props.data.settingsData.suggested_by;
    newData.journey = props.data.settingsData.journey;
    newData.defaultRestaurant = props.data.settingsData.defaultRestaurant;
    setData(newData);
    handleChange(e);
  }

  const phoneFieldHandle = (isValid, value, selectedCountryData, fullNumber, extension) => {
    // console.log(fullNumber);
    // console.log(selectedCountryData);
    // console.log(value);
    // console.log(isValid);
    const nextValue = isValid
      ? fullNumber.replace(/\s|-/g, '')
      : value;
    setFullphonenumber(fullNumber);
    const ccode = `+${selectedCountryData.dialCode}`;
    setPhoneCode(ccode);

    setValues((values) => ({ ...values, phone_number: nextValue.replace(ccode, '') }));
    setPhoneNumber(nextValue.replace(ccode, ''));

    if (nextValue == '' || nextValue == null) {
      inputRef.current.phone_number.classList.remove('phone-focus');
    } else {
      inputRef.current.phone_number.classList.add('phone-focus');
    }
    const newData = { ...data };
    newData.phone_number = nextValue;
    newData.fullphonenumber = fullNumber;
    newData.phone_number_code = `+${selectedCountryData.dialCode}`;
    newData.phone_number = nextValue;
    newData.phone_number_code.replace('++', '+');
    setData(newData);
    /* const nextValue = isValid
        ? fullNumber.replace(/\s|-/g, '')
        : value;
        //const nextValue = value.replace(/\D/g, "");
          const ccode = selectedCountryData.dialCode;
          setPhoneCode('+'+ccode);
          setValues(values => ({ ...values, ['phone_number']: nextValue }));
          setPhoneNumber(nextValue);
          if(nextValue == "" || nextValue == null){
            inputRef.current['phone_number'].classList.remove('phone-focus')
          }else{
            inputRef.current['phone_number'].classList.add('phone-focus')
          }
          const newData = {...data};
          newData['phone_number'] = nextValue;
          newData['phone_number_code'] = '+'+ccode;
          newData['phone_number'] = nextValue;
          setData(newData); */
  };
  const handleSelectFlag = (currentNumber, seletedCountryData, fullNumber, isValid) => {
    setPhoneCode(fullNumber);
  };

  function adjustPlaceholder(e, inpoutfield) {
    if (inpoutfield == 'phone_number') {
      inputRef.current[inpoutfield].classList.add('phone-focus');
      document.querySelector('.phone-numer-wth-code').focus();
    } else {
      inputRef.current[inpoutfield].focus();
    }
  }
  const handlePhoneFocusLost = (e) => {
    inputRef.current.phone_number.classList.remove('phone-focus');
  };

  const validationError = [];
  const errorMsgs = [];
  const error_guest = `${t('LANG_SELECT_GUEST')}`;
  const error_date = `${t('LANG_SELECT_DATE')}`;
  const error_slot = `${t('LANG_SELECT_SLOT')}`;
  const error_area = `${t('LANG_SELECT_AREA')}`;
  function formSubmit() {
    // props.finalSubmit(data);
    const prossettings = props.data.settingsData;
    if (prossettings.selectedGuest === '') {
      validationError.push(error_guest);
    }
    if (prossettings.selectedDate === '') {
      validationError.push(error_date);
    }
    if (prossettings.selectedSlot === '') {
      validationError.push(error_slot);
    }
    if (props.data.settingsData.res_settings.area_path === 'yes' && prossettings.selectedArea === '') {
      validationError.push(error_area);
    }
    if (validationError.length === 0) {
      // console.log(data);
      props.finalSubmit(data);
    } else {
      let u = 0;
      for (const err of validationError) {
        errorMsgs.push(<div key={u} className="clienterror">{err}</div>);
        u++;
      }
      setClientError(errorMsgs);
    }
  }
  window.$('.ui-tooltip').remove();
  let restaurantName;
  if (props.data.settingsData.poolRestaurantName) {
    restaurantName = props.data.settingsData.poolRestaurantName;
  } else {
    restaurantName = props.data.settingsData.restaurantInfo.name;
  }
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton accbtn="formblock">
          <MailIcon sx={{ color: iconColor }} />
          <span className="guests-opl">{t('LANG_CONTACT_DETAILS')}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <form autoComplete="off" name="widgetcontact" id="widgetcontactform" onSubmit={handleSubmit} noValidate>
          <div className=" content contacr-details">
            <div className="divider-field-wrapper">
              <div className={`${values?.first_name ? 'hasValue' : ''} field-wrapper `} onClick={(e) => adjustPlaceholder(e, 'firstname')}>
                <input ref={(el) => inputRef.current.firstname = el} className={`${errors.first_name && 'isdanger'}`} onChange={(e) => formHandle(e)} type="text" id="first_name" name="first_name" value={values.first_name || ''} />
                <div className="field-placeholder">
                        {t('LANG_FIRTNAME')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>

              </div>
              <div className={`${values?.last_name ? 'hasValue' : ''} field-wrapper field-wrapper2`} onClick={(e) => adjustPlaceholder(e, 'lastname')}>
                <input ref={(el) => inputRef.current.lastname = el} className={`${errors.last_name && 'isdanger'}`} onChange={(e) => formHandle(e)} type="text" name="last_name" id="last_name" />
                <div className="field-placeholder">
                        {t('LANG_LASTNAME')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
            </div>
            <div className="divider-field-wrapper1" onClick={(e) => adjustPlaceholder(e, 'email')}>
              <div className={`${values?.email ? 'hasValue' : ''} field-wrapper`}>
                <input ref={(el) => inputRef.current.email = el} className={`${errors.email && 'isdanger'}`} onChange={(e) => formHandle(e)} type="email" name="email" id="email" />
                <div className="field-placeholder">
                        {t('LANG_EMAIL')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>
            </div>
            <div className="divider-field-wrapper" ref={(el) => inputRef.current.phone_number = el}>
              <div className={`${values?.phone_number ? 'hasValue' : ''} field-wrapper ` + `${errors.phone_number && 'isdangerfull-phone'}`}>
                <IntlTelInput
                        onPhoneNumberBlur={handlePhoneFocusLost}
                        separateDialCode="false"
                        fieldName="phone_number"
                        containerClassName="intl-tel-input"
                        inputClassName="phone-numer-wth-code form-control "
                        defaultCountry="fi"
                        placeholder=""
                        formatOnInit
                        onPhoneNumberChange={phoneFieldHandle}
                        onSelectFlag={handleSelectFlag}
                        onPhoneNumberFocus={(e) => adjustPlaceholder(e, 'phone_number')}
                        value={phone_number}
                      />
                <div className="field-placeholder floating-label" onClick={(e) => adjustPlaceholder(e, 'phone_number')}>
                        {t('LANG_PHONE_NUMBER')}
                        {' '}
                        <sup>*</sup>
                        {' '}
                      </div>
              </div>

              {/* <div className="field-wrapper">
                            <CountryDropdown  id="phone_number_code" name="phone_number_code" className='YOUR_CSS_CLASS' preferredCountries={['in', 'fi']}  value="" handleChange={e => console.log(e)}></CountryDropdown>
                            </div>
                            <div  className={(values?.phone_number? "hasValue" : '') + " field-wrapper field-wrapper-next3"}  onClick={e=>adjustPlaceholder(e, 'phone_number')}>
                                <input ref={el => inputRef.current['phone_number'] = el} className={`${errors.phone_number && 'isdanger'}`} onChange={e=>formHandle(e)} name="phone_number" type="tel" id="phone_number"/>

                            </div> */}
            </div>
            <h2 className="Additional-info234">{t('LANG_ADDITIONAL_INFO')}</h2>
            <div className="divider-field-wrapper1">
              <div className={`${values?.special_request ? 'hasValue' : ''} field-wrapper`} onClick={(e) => adjustPlaceholder(e, 'special_request')}>
                <textarea ref={(el) => inputRef.current.special_request = el} onChange={(e) => formHandle(e)} name="special_request" id="special_request" />
                <div className="field-placeholder">
                        {t('LANG_SPECIAL_NEEDS')}
                        {' '}
                      </div>
              </div>
            </div>

            <div className="divider-field-wrapper">
              
              <div className="form-group-nature">
                <input onChange={(e) => formHandle(e)} type="checkbox" id="res_newsletter" name="res_newsletter" />
                <label htmlFor="res_newsletter">
                        <span>
                            {t('LANG_RES_NEWSLETTER')}
                            {' '}
                            <b>
                                {' '}
                                {restaurantName}
                              </b>
                          </span>
                      </label>
              </div>
               {/*
              <div className="form-group-nature">
                <input className={`${errors.newsletter && 'isdanger'}`} onChange={(e) => formHandle(e)} type="checkbox" id="newsletter" name="newsletter" />
                <label htmlFor="newsletter"><span>{t('LANG_NEWSLETTER')}</span></label>
              </div>
              */}
              <div className="form-group-nature">
                <input className={`${errors.agreecheckbox && 'isdanger'}`} onChange={(e) => formHandle(e)} type="checkbox" id="agreecheckbox" name="agreecheckbox" />
                <label htmlFor="agreecheckbox">
                        {t('LANG_TERMS_CONDITION_TEXT_1')}
                        {' '}
                        <a style={{ color: iconColor }} href={i18n.language === 'en' ? process.env.REACT_APP_PREGO_TERMS_CONDITION_EN : process.env.REACT_APP_PREGO_TERMS_CONDITION_FI} target="_blank" rel="noreferrer">{t('LANG_TERMS_CONDITION_TEXT_2')}</a>
                        {' '}
                        {t('LANG_TERMS_CONDITION_TEXT_3')}
                        {' '}
                        <a style={{ color: iconColor }} target="_blank" href={i18n.language === 'en' ? process.env.REACT_APP_PREGO_TERMS_CONDITION_EN : process.env.REACT_APP_PREGO_TERMS_CONDITION_FI} rel="noreferrer">{t('LANG_TERMS_CONDITION_TEXT_4')}</a>
                        {' '}
                        <sup>*</sup>
                      </label>
              </div>
            </div>
            <div className="divider-field-wrapper">
              {clientError}
            </div>
            <div className="divider-field-wrapper">
              <input type="submit" value={t('LANG_BTN_CONFIRM_BOOKING')} style={{ backgroundColor: iconColor }} className="sender-but" disabled={disabledBtn} />
            </div>
          </div>
        </form>
      </AccordionItemPanel>
    </AccordionItem>
  );
}
