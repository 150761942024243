export default function validate(values) {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Enter first name';
  }
  if (!values.last_name) {
    errors.last_name = 'Enter last name';
  }
  if (!values.email) {
    errors.email = 'Enter email';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.phone_number) {
    errors.phone_number = 'Enter phone number';
  } else if (values.phone_number.length > 12) {
    errors.phone_number = 'Phone number can not enter morthan 12';
  }
  if (values.agreecheckbox === undefined || (values.agreecheckbox !== undefined && !values.agreecheckbox)) {
    errors.agreecheckbox = 'Must select the terms and condition';
  }
  // if(values['newsletter']=== undefined || (values['newsletter']!== undefined && !values.newsletter)){
  //     errors.newsletter = "Must select the terms and condition"
  // }
  return errors;
}
