import React, { Component, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IntlTelInput, { IntlTelInputProps, CountryData } from 'react-intl-tel-input';
import useForm from './widgetForm/useForm';
import validate from './widgetForm/ContactUsFormValidationRules';
import 'react-intl-tel-input/dist/main.css';

function ContactUsComponent(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    phone_number_code: '',
    phone_number: '',
    fullphonenumber: '',
    contact_notes: '',
  });
  const [cphonenumber, setPhoneNumber] = useState();
  const [fullphonenumber, setFullphonenumber] = useState();

  const [cphonenumber_code, setPhoneCode] = useState();
  const { iconColor } = props.data.settingsData;
  const disabledBtn = props.data.settingsData.isSubmitClicked ? 'disabled' : false;
  const inputRef = useRef([]);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
  } = useForm(formSubmit, validate);
  function formHandle(e) {
    const newData = { ...data };
    newData.phone_number = cphonenumber;
    newData.phone_number_code = cphonenumber_code;
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        newData[e.target.id] = e.target.value;
      }
    } else {
      newData[e.target.id] = e.target.value;
    }

    setData(newData);

    handleChange(e);
  }
  function adjustPlaceholder(e, inpoutfield) {
    if (inpoutfield == 'phone_number') {
      inputRef.current[inpoutfield].classList.add('phone-focus');
      document.querySelector('.phone-numer-wth-code').focus();
    } else {
      inputRef.current[inpoutfield].focus();
    }
  }
  const handlePhoneFocusLost = (e) => {
    inputRef.current.phone_number.classList.remove('phone-focus');
  };
  function formSubmit() {
    props.contactusSubmit(data);
  }
  const phoneFieldHandle = (isValid, value, selectedCountryData, fullNumber, extension) => {
    const nextValue = isValid
      ? fullNumber.replace(/\s|-/g, '')
      : value;
    setFullphonenumber(fullNumber);
    const ccode = `+${selectedCountryData.dialCode}`;
    setPhoneCode(ccode);
    setValues((values) => ({ ...values, phone_number: nextValue.replace(ccode, '') }));
    setPhoneNumber(nextValue.replace(ccode, ''));
    const newData = { ...data };
    newData.fullphonenumber = fullNumber;
    setData(newData);
  };
  const handleSelectFlag = (currentNumber, seletedCountryData, fullNumber, isValid) => {
    const ccode = seletedCountryData.dialCode;
    setPhoneCode('+', ccode);
  };
  return (
    <form autoComplete="off" name="contactusform" id="contactusform" onSubmit={handleSubmit} noValidate>
      <div className=" content">
        <div className="divider-field-wrapper">
          <div className={`${values?.contact_first_name ? 'hasValue' : ''} field-wrapper `}>
            <input className={`${errors.contact_first_name && 'isdanger'}`} onChange={(e) => formHandle(e)} type="text" id="contact_first_name" name="contact_first_name" value={values.contact_first_name || ''} />
            <div className="field-placeholder">
              {t('LANG_FIRTNAME')}
              {' '}
              <sup>*</sup>
              {' '}
            </div>

          </div>
          <div className={`${values?.contact_last_name ? 'hasValue' : ''} field-wrapper field-wrapper2`}>
            <input className={`${errors.contact_last_name && 'isdanger'}`} onChange={(e) => formHandle(e)} type="text" name="contact_last_name" id="contact_last_name" />
            <div className="field-placeholder">
              {t('LANG_LASTNAME')}
              {' '}
              <sup>*</sup>
              {' '}
            </div>
          </div>
        </div>
        <div className="divider-field-wrapper1">
          <div className={`${values?.contact_email ? 'hasValue' : ''} field-wrapper`}>
            <input className={`${errors.contact_email && 'isdanger'}`} onChange={(e) => formHandle(e)} type="email" name="contact_email" id="contact_email" />
            <div className="field-placeholder">
              {t('LANG_EMAIL')}
              {' '}
              <sup>*</sup>
              {' '}
            </div>
          </div>
        </div>
        <div className="divider-field-wrapper" ref={(el) => inputRef.current.phone_number = el}>
          <div className={`${values?.phone_number ? 'hasValue' : ''} field-wrapper ` + `${errors.phone_number && 'isdangerfull-phone'}`}>
            <IntlTelInput
              onPhoneNumberBlur={handlePhoneFocusLost}
              separateDialCode="false"
              fieldName="phone_number"
              containerClassName="intl-tel-input"
              inputClassName="phone-numer-wth-code form-control "
              defaultCountry="fi"
              placeholder=""
              formatOnInit="true"
              onPhoneNumberChange={phoneFieldHandle}
              onSelectFlag={handleSelectFlag}
              onPhoneNumberFocus={(e) => adjustPlaceholder(e, 'phone_number')}
            />
            <div className="field-placeholder floating-label" onClick={(e) => adjustPlaceholder(e, 'phone_number')}>
              {t('LANG_PHONE_NUMBER')}
              {' '}
              <sup>*</sup>
              {' '}
            </div>

          </div>
        </div>
        <div className="divider-field-wrapper1">
          <div className={`${values?.contact_notes ? 'hasValue' : ''} field-wrapper`}>
            <textarea onChange={(e) => formHandle(e)} name="contact_notes" id="contact_notes" />
            <div className="field-placeholder">
              {t('LANG_NOTE')}
              {' '}
            </div>
          </div>
        </div>
        <div className="divider-field-wrapper">
          <input type="submit" value={t('LANG_SEND')} style={{ backgroundColor: iconColor }} className="sender-but" disabled={disabledBtn} />
        </div>
      </div>
    </form>
  );
}

export default ContactUsComponent;
